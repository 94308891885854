<template>
    <div class="mt-2">
        <h2><span class="badge badge-self mt-2 text-white">學術文章</span></h2>
        <div v-for="item in articles" :key="item">
            <router-link :to="{ name: 'single', params: { id: item.resourceID } }">
                {{ item.title }}
            </router-link>
        </div>
        <div class="pt-3">
            <div class="row shadow-sm p-3 mb-3 bg-body">
                <div class="col-xl-3 col-md-6 col-12">
                    <router-link class="text-decoration-none text-dark" :to="{ name: 'article', params: { type: 'info' } }">
                        <div class="row info p-3 my-1 mx-2 rounded-3">
                            <div class="col-4">
                                <img class="img-fluid" src="@/assets/code-editor.png" />
                            </div>
                            <div class="col">
                                <h4 class="text-center">資訊軟體產業</h4>
                                <div class="text-center font fw-bolder">{{ thousandFilter(typeList["Application Software"]) }}篇</div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-xl-3 col-md-6 col-12">
                    <router-link class="text-decoration-none text-dark" :to="{ name: 'article', params: { type: 'ai' } }">
                        <div class="row ai p-3 my-1 mx-2 rounded-3">
                            <div class="col-4">
                                <img class="img-fluid" src="@/assets/artificial-intelligence.png" />
                            </div>
                            <div class="col">
                                <h4 class="text-center">AI研發人才產業</h4>
                                <div class="text-center font fw-bolder">{{ thousandFilter(typeList["Artificial Intelligence R&D Industry Talents"]) }}篇</div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-xl-3 col-md-6 col-12">
                    <router-link class="text-decoration-none text-dark" :to="{ name: 'article', params: { type: 'digital' } }">
                        <div class="row digital p-3 my-1 mx-2 rounded-3">
                            <div class="col-4">
                                <img class="img-fluid" src="@/assets/digital-marketing.png" />
                            </div>
                            <div class="col">
                                <h4 class="text-center">數位內容產業</h4>
                                <div class="text-center font fw-bolder">{{ thousandFilter(typeList["Digital Content"]) }}篇</div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-xl-3 col-md-6 col-12">
                    <router-link class="text-decoration-none text-dark" :to="{ name: 'article', params: { type: 'media' } }">
                        <div class="row media p-3 my-1 mx-2 rounded-3">
                            <div class="col-4">
                                <img class="img-fluid" src="@/assets/video-games.png" />
                            </div>
                            <div class="col">
                                <h4 class="text-center">多媒體遊戲產業</h4>
                                <div class="text-center font fw-bolder">{{ thousandFilter(typeList["Multimedia"]) }}篇</div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <p class="fs-4 my-3 text-dark fw-bolder">近期更新收錄論文</p>
        <div v-if="lastestArticle.length == 0" class="fw-bolder text-secondary text-centet my-3">
            尚無資料
        </div>
        <table v-else class="table table-hover">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col" class="text-start">標題</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in lastestArticle" :key="item">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>
                        <!-- 來源顯示 -->
                        <span class="badge badge-event mx-2">{{ item.resourceFrom }}</span>

                        <!-- 分類顯示 -->
                        <span v-if="item.name === 'Application Software'" class="badge mx-1 opacity-75 text-bg-primary">資訊軟體</span>
                        <span v-if="item.name === 'Artificial Intelligence R&D Industry Talents'" class="badge mx-1 opacity-75 text-bg-info">AI研發人才</span>
                        <span v-if="item.name === 'Digital Content'" class="badge mx-1 opacity-75 text-bg-warning">數位內容</span>
                        <span v-if="item.name === 'Multimedia'" class="badge mx-1 opacity-75 text-bg-danger">多媒體遊戲</span>
                        <!-- 連結顯示 -->
                        <a target="_blank"
                            class="mt-2 lh-lg text-muted link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                            :href="item.doi">
                            {{ item.title.length > 100 ? item.title.slice(0, 100) + "..." : item.title }}
                        </a>
                    </td>
                    <td>
                        <router-link :to="{ name: 'single', params: { id: item.id } }"><button class="btn my-1 mx-1 btn-secondary">查看分析</button></router-link>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="text-end">
            <button @click="redirectArticle" type="button" class="btn btn-light">查看更多 ></button>
        </div>
    </div>
</template>

<script setup>
import globalParams from '../components/GlobalParams.vue'
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const articles = ref([]);
const typeList = ref({
    "Application Software": 0,
    "Artificial Intelligence R&D Industry Talents": 0,
    "Multimedia": 0,
    "Digital Content": 0
});
const lastestArticle = ref([]);

const thousandFilter = (value) => {
    let str = value.toString();
    return str.replace(/(\d{1,3})(?=(\d{3})+$)/g, "$1,");
}

const redirectArticle = () => {
    router.push({ name: 'article'});
}

const callMainContent = () => {
    fetch(`${globalParams.backend}/article/info`, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(data => {
            const result = data.data;
            result.forEach(element => {
                typeList.value[element.name] = Number(element.sum);
            });
        })
        .catch(error => {
            console.error('錯誤資訊:', error);
        });
}

const callLastestContent = () => {
    fetch(`${globalParams.backend}/article/lastest`, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(data => {
            lastestArticle.value = data.data;
        })
        .catch(error => {
            console.error('錯誤資訊:', error);
        });
}

onMounted(() => {
    callLastestContent();
    callMainContent();
});
</script>

<script>
export default {
    name: 'PaperArticle',
    return() {
    },
    filters: {

    }
}
</script>

<style scoped>
img {
    max-width: 70px;
}

.font {
    font-size: 1.35rem;
}

.info {
    background: #E5F3FE;
}

.info .font {
    color: #426B95;
}

.ai {
    background: #E1E6FB;
}

.ai .font {
    color: #071B84;
}

.digital {
    background: #F9E3CC;
}

.digital .font {
    color: #E48342;
}

.media {
    background: #F9E1E1;
}

.media .font {
    color: #A2271E;
}

h4 {
    font-weight: bolder;
    font-size: 1.2rem;
}

.badge-self {
    background: #3881C7;
}

.badge-event {
    background-color: #b2bec3;
}

</style>