import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/article/:type?',
        name: 'article',
        component: function () {
            return import(/* webpackChunkName: "Article" */ '../views/ArticleView.vue')
        },
    },
    {
        path: '/event',
        name: 'event',
        component: function () {
            return import('../views/EventView.vue')
        },
    },
    {
        path: '/single/:id?',
        name: 'single',
        component: function () {
            return import('../views/SingleView.vue')
        },
    },
    {
        path: '/login',
        name: 'login',
        component: function () {
            return import('../views/LoginView.vue')
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: function () {
            return import('../views/LogoutView.vue')
        }
    },
    {
        path: '/back',
        name: 'Back',
        component: () => import(/* webpackChunkName: "back" */ '../views/BackView.vue'),
        meta: { requiresAuth: true } // 添加一个 meta 字段，表示该路由需要身份验证
    },
    {
        path: '/:pathMatch(.*)',
        name: 'NotFound',
        component: () => import('../views/NotFound.vue'),
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    // 如果目标路由需要验证用户身份
    if (to.meta.requiresAuth) {
        const accessToken = localStorage.getItem('accessToken'); // 从本地存储中获取 Google Access Token
        if (!accessToken) {
            // 如果用户未登录，重定向到登录页面
            next('/login');
        } else {
            try {
                const result = await verifyGoogleAccessToken(accessToken);
                if (result.isValid) {
                    // 令牌有效，继续导航
                    next();
                } else {
                    // 令牌无效，重定向到登录页面
                    next('/login');
                }
            } catch (error) {
                console.error('验证 Access Token 时出错：', error);
                // 验证过程中出现错误，重定向到登录页面
                next('/login');
            }
        }
    } else {
        next();
    }
});


async function verifyGoogleAccessToken(accessToken) {
    try {
        const response = await fetch(`https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${accessToken}`);
        const data = await response.json();
        if (data.error) {
            return { isValid: false, error: data.error.message };
        } else {
            return { isValid: true };
        }
    } catch (error) {
        return { isValid: false, error: error.message };
    }
}

export default router;
