<template>
  <div class="d-flex flex-column min-vh-100">
    <main class="flex-fill">
      <router-view />
    </main>
    <footer class="mt-5 p-3 bg-dark text-white text-center">
      © 2024 計畫資源論文暨資訊推薦平台 All rights reserved.
    </footer>
  </div>
</template>
