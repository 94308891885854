<template>
    <NavbarComponent></NavbarComponent>
    <div class="container">
        <PaperArticle />
        <EventInfo />
    </div>
</template>

<script>
// @ is an alias to /src
import PaperArticle from '@/components/PaperArticle.vue'
import EventInfo from '@/components/EventInfo.vue'
import NavbarComponent from '@/components/NavbarComponent.vue'

export default {
    name: 'HomeView',
    components: {
        PaperArticle,
        EventInfo,
        NavbarComponent,
    },
    data() {
        return {
        }
    }
}
</script>

<style scoped>
body {
    background-color: #F1F8FE;
}
</style>
