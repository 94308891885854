<template>
    <nav class="sticky-top navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
            <router-link class="navbar-brand" to="/">計畫資源論文暨資訊推薦平台</router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02"
                aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                </ul>
                <form class="d-flex" role="search">
                    <router-link class="nav-link mx-3 active" aria-current="page" to="/">首頁</router-link>
                    <router-link class="nav-link mx-3 " aria-current="page" to="/article">學術文章</router-link>
                    <router-link class="nav-link mx-3" aria-current="page" to="/event">學術活動</router-link>
                    <router-link class="nav-link mx-3" aria-current="page" to="/back">使用者專區</router-link>
                </form>
            </div>
        </div>
    </nav>
</template>

<script setup>
</script>

<style scoped>
body {
    overflow-x: hidden;
}
</style>