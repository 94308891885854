<script>

/**
 * Formal Perfix
 */
const backend = "/api";

/**
 * Debug Perfix
 */
// const backend = "http://127.0.0.1:3301/api";

export default {
    backend
}
</script>