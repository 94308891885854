<template>
    <div>
        <h2>
            <span class="badge badge-self text-white ">
                學術資訊
            </span>
        </h2>
    </div>
    <div>
        <table class="table table-hover">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col"></th>
                    <th scope="col">活動講座與研討會資訊</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in eventList" :key="item">
                    <th scope="row">{{ index + 1 }}</th>
                    <td class="h5"><span class="badge badge-event">{{ item.name }}</span></td>
                    <td>
                        <a target="_blank"
                            class="text-muted link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                            :href="item.url">{{ item.title.length > 200 ? item.title.slice(0, 200) + "..." :
                            item.title}}</a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="text-end">
            <button @click="redirectEvent" type="button" class="btn btn-light">查看更多 ></button>
        </div>
    </div>
    <div v-if="!eventList" class="text-center">
        <p class="fs-2 my-3 text-secondary fw-bolder">目前暫無學術資訊</p>
    </div>
</template>


<script setup>
import globalParams from '../components/GlobalParams.vue'
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const eventList = ref([]);

const callSeminarTop = () => {
    fetch(`${globalParams.backend}/seminar/top`, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(data => {
            eventList.value = data.data
        })
        .catch(error => {
            console.error('錯誤資訊:', error);
        });
}

const redirectEvent = () => {
    router.push({ name: 'event'});
}

onMounted(() => {
    callSeminarTop()
})
</script>


<style scoped>
.badge-self {
    background: #3881C7;
}

.badge-event {
    background-color: #00b894;
}

.tag-font-size {
    font-size: 2rem;
}
</style>